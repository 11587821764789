<template>
    <v-container fluid>
        <v-row align-start justify-center row wrap>
            <v-col cols="12">
                 <v-card flat align-content-center>
                    <v-row align-start justify-center row wrap>
                        <v-col cols="12" class="text-xs-left" ma-2>
                            <div class="title">{{ detailStatus }}</div>
                    <!--        <v-switch v-show="!localLoading" @change="changeStatus(null, true)"
                                v-model="futureMembers"
                                :label="`Audience: ${audience}`"
                            ></v-switch>
                            -->
                            <v-col cols="12" sm="6" class="mt-2">
                                <v-select :items="audiences" v-model="audience"
                                outlined dense hide-details
                                label="Audience"
                                item-text="text"
                                item-value="value"
                                :readonly="!allowApprove"
                                @change="changeAudience(audience)"
                                ></v-select>
                            </v-col>
                            <!--
                            <v-col cols="12" sm="6" class="mt-2">
                                <v-select :items="fulfillmentOptions" v-model="fulfillments"
                                outlined dense hide-details
                                multiple
                                label="Fulfillment Options"
                                item-text="location"
                                :readonly="!allowApprove"
                                @change="changeFulfillment(fulfillment)"
                                ></v-select>
                            </v-col>
                        -->
                        <v-col cols="12" class="mt-0">
                            <div class="text-subtitle-2">Fulfillment Options</div>

                            <!--
                            <v-row>
                                <v-list-item-group v-model="selectedFulfillments" multiple>
                                    <v-list dense>
                                        <v-list-item
                                        v-for="option in fulfillmentOptions"
                                        :key="option.type+option.location"
                                        >
                                        <v-list-item-action>
                                            <v-checkbox 
                                                :value="option"
                                                @change="changeFulfillment(option)"
                                            ></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ titleCase(option.type) + ' @ ' + option.location }}</v-list-item-title>
                                        </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-list-item-group>
                          
                            </v-row>     -->

                            <v-row>
                                <v-col
                                v-for="(option, index) in fulfillments"
                                :key="option.location"
                                cols="12" sm="4" class="mb-n6"
                                >
                                <v-checkbox
                                    v-model="option.checked"
                                    :label="titleCase(option.type) + ' @ ' + option.location"
                                    :disabled="!allowApprove"
                                    @change="changeFulfillment()"
                                    outlined dense hide-details
                                ></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-col>


                            <v-progress-circular v-show="localLoading"
                                :width="3"
                                color="blue"
                                indeterminate
                            ></v-progress-circular>

                            <v-col v-if="audience!=null && audience!=''" cols="12" class="mt-7">
                                <div v-show="allowApprove" class="text-subtitle-2 ma-1">
                                    This product is hidden from members and unavailable for purchase. 
                                </div>
                                <v-btn v-show="allowApprove" outlined color="primary" @click="changeStatus('Approved')" :loading="localLoading">Make Product Live</v-btn>
                                
                                <div v-show="allowMakeDraft" class="font-weight-thin ma-1">
                                    To make this product unavailable for purchase right now, click below to place it back into draft status.
                                </div>
                                <v-btn v-show="allowMakeDraft" outlined color="primary" @click="changeStatus('Draft')" :loading="localLoading">Hide Product</v-btn>
                            </v-col>
                            
                            <v-col cols="12">
                                <div v-show="allowApprove" class="text-subtitle-2 ma-1 mt-3">
                                    To change product info, edit in brewer.ai, and then re-import here.
                                </div>
                                <v-btn v-show="allowApprove" outlined color="primary" @click="reImport" :loading="localLoading">Re-Import</v-btn>
                            </v-col>
                            
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import api from '@/store/api'
import axios from 'axios'
import moment from 'moment'
import AllMixins from '@/mixins/AllMixins'
export default {
    data () {
        return {
            alert: false,
            showTitle: false,
            showDetails: false,
        //    futureMembers: false,
            localLoading: false,
            approved: false,
            fulfillments: [],
        }
    },
    props: ['product'],
    mounted: function() {

        let audienceValue = 'members'
        if(this.product.audience){
            audienceValue = this.product.audience
        }
        this.audience = this.audiences.find(
            audience => audience.value === audienceValue
        )

        // if product has fulfillments, import current selections, otherwise initialize with all options
        this.fulfillments = this.addChecksToOptions(this.fulfillmentOptions, this.product.fulfillments)
        this.changeFulfillment()
    
    },
    mixins: [AllMixins],
    computed: {
        detailStatus () {
            let status = this.product.status
            if(status == "Approved"){
                if(this.closed(this.product.pickupEnd))
                    return "Expired"
                if(this.closed(this.product.orderEnd))
                    return "In Pickup Period"
                else    
                    return "Orders Live"
            }
            return status
        },
        allowApprove () {
            return this.product.status == "Draft"
        },
        allowMakeDraft () {
            return this.detailStatus == "Orders Live"
        },
        selectedFulfillments(){
            return this.getSelectedWithoutChecks(this.fulfillments)
        },
        loading () { return this.$store.state.loading },
    },
    methods: {
        ...mapMutations([
            'setLoading',
        ]),
        addChecksToOptions (options, selected) {
            let newOptions = JSON.parse(JSON.stringify(options))
            newOptions.forEach( option => {
                if(selected){
                    selected.forEach( selectedOption => {
                        if(selectedOption.location == option.location)
                            option.checked = true
                    })
                }
                else
                    option.checked = true // if no selected, then all are checked
            })
            return newOptions
        },
        getSelectedWithoutChecks(options){
            const selected = options.filter(option => option.checked)
            let clone = JSON.parse(JSON.stringify(selected))
            clone.forEach( fulfillment => {
                delete fulfillment.checked
            })
            return clone
        },
        reImport () {
            //import just this one product from Airtable and overwrite (signal to server we want to update just this product by supplying the productId)
            this.setLoading(true)
            this.confirmImport = false
            let uid = this.$store.state.user.uid
            var functionsCall = global.FIREBASE.functions.httpsCallable('addnewproducts')
            functionsCall({uid: uid,})
            .then( (response) => {
                api.getOrderDashboard()
                return 
            })

        },
        closed (dateStr) {
            let diff = moment(dateStr, "YYYY-MM-DD @HH:mm").diff(moment(), "hours")
            return diff <= 0
        },
        changeAudience(newAudience){
            let changedData = {audience: newAudience}
            this.updateProduct(changedData)
        },
        changeFulfillment(){
            let changedData = {fulfillments: this.selectedFulfillments}
            //console.log(this.selectedFulfillments)
            this.updateProduct(changedData)
        },
        changeStatus(newStatus){
            let changedData = {status: newStatus}
            this.updateProduct(changedData)
        },
        updateProduct (changedData) {            
            this.localLoading = true
            let uid = this.$store.state.user.uid
            let self = this
            const functionServer = ENV.functionsServer()
            console.log("productId = " + self.product.productId)
            return new Promise( function(resolve, reject) {
                // let changedData = {}
                // if(newStatus != null)
                //     changedData.status = newStatus
                // if(newAudience)
                //     changedData.audience = self.audience
                axios.post(functionServer + "updateproduct", {uid: uid, productId: self.product.productId, updatedData: changedData})
                .then( function(response) {
                    self.$emit('product-updated',changedData)
                    self.localLoading = false
                    console.log("successfully updated product status.")
                    resolve(response);
                })
                .catch( error => {
                    self.localLoading = false
                    console.log("error updating product status: " + error)
                    reject(error)
                })
            })
        },
        refreshAllProducts () {
            this.setLoading(true)
            api.getOrderDashboard()
        },
    }
}
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: auto;
    border-spacing: 1px;
}

td, th {
  border: 1px solid #dddddd;
  text-align: right;
  padding: 5px;
  height: 10px
}

th:nth-child(1) {
  text-align: left;
}
</style>