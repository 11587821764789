<template>
    <v-container fluid>
        <v-row align-start justify-center row wrap>
            <v-col cols="12">
            <v-tabs grow>

                <v-tab>Status</v-tab>
                <v-tab-item>
                    <ProductStatus
                    :product="item"
                    @product-updated="productUpdated"
                    />
                </v-tab-item>

                <v-tab>Sales</v-tab>
                <v-tab-item>

                    <div v-for="(variation, index) in item.variations" :key="index" class="pa-5">
                    
                        <v-card flat outlined align-content-center class="pa-2">
                        
                            <h4 class="ma-1">{{ variation.name }}</h4>

                            <v-row justify="center" class="mt-2">

                            <div v-for="(location, index) in variation.count" :key="index" class="pa-1">
                                <h4>{{ location.name }}</h4>
                                <v-col >
                                    
                                    <table class="narrow-table">
                                        <thead :style="{ backgroundColor: `${$vuetify.theme.currentTheme.surface}`}">
                                            <tr>
                                                <th></th>
                                                <th>Members</th>
                                                <th>Units</th>
                                                <th v-if="location.cases">Cases</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Ordered</th>
                                                <td>{{ location.members.ordered }}</td>
                                                <td>{{ location.units.ordered }}</td>
                                                <td v-if="location.cases">{{ location.cases.ordered }}</td>
                                            </tr>
                                            <tr>
                                                <th>Picked Up</th>
                                                <td>{{ location.members.completed }}</td>
                                                <td>{{ location.units.completed }}</td>
                                                <td v-if="location.cases">{{ location.cases.completed }}</td>
                                            </tr>
                                            <tr>
                                                <th>Refunded</th>
                                                <td>{{ location.members.refunded }}</td>
                                                <td>{{ location.units.refunded }}</td>
                                                <td v-if="location.cases">{{ location.cases.refunded }}</td>
                                            </tr>
                                            <tr>
                                                <th>Outstanding</th>
                                                <td>{{ location.members.ordered - location.members.completed - location.members.refunded }}</td>
                                                <td>{{ location.units.ordered - location.units.completed - location.units.refunded }}</td>
                                                <td v-if="location.cases">{{ location.cases.remaining }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </v-col>
                            </div>
                            
                            <v-col v-if="variation.options != undefined" >

                                <table class="narrow-table" v-for="(option, index) in variation.options" v-bind:key="option.name" >
                                    <thead :style="{ backgroundColor: `${$vuetify.theme.currentTheme.surface}`}">
                                        <tr> 
                                            <th>{{option.name}}</th>
                                            <th >Ordered</th>
                                        </tr>
                                    </thead>    
                                    <tr v-for="(choice) in option.choices" v-bind:key="choice+index">
                                        <th>{{choice}}</th>
                                        <td>{{ option.count[choice] }}</td>
                                    </tr>
                                </table>
                            </v-col>
                        </v-row>
             
                    </v-card>
                </div>
       
                </v-tab-item>

                <v-tab>Product Info</v-tab>
                <v-tab-item>
                    <v-card flat cols="12">
                    <ProductInfo 
                    :item="item" 
                    :show="true"
                    :showDates="true"
                    />
                </v-card>
                </v-tab-item>
            </v-tabs>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import ProductInfo from '@/components/product/ProductInfo'
import ProductStatus from '@/components/product/ProductStatus'
import moment from 'moment'
export default {
    components: {
        ProductInfo,
        ProductStatus
    },
    data () {
        return {
            alert: false,
            showTitle: false,
            showDetails: false,
            headers: [
                { text: '', value: 'name'},
                { text: 'Ordered', value: 'ordered' },
                { text: 'Picked Up', value: 'completed' },
                { text: 'Refunded', value: 'refunded' },
                { text: 'Outstanding', value: 'outstanding' },
            ],
        }
    },
    props: ['item'],
    computed: {
        loading () { return this.$store.state.loading },
    },
    methods: {
        ...mapMutations([
            'setLoading',
        ]),
        getTableData (variation) {
            let data = []
            data.push({
                name: "Members",
                ordered: variation.membersOrdered,
                completed: variation.membersCompleted,
                refunded: variation.membersRefunded,
                outstanding: variation.membersOrdered - variation.membersCompleted - variation.membersRefunded
            })
            data.push({
                name: "Units",
                ordered: variation.ordered,
                completed: variation.completed,
                refunded: variation.refunded,
                outstanding: variation.ordered - variation.completed - variation.refunded
            })
            if(variation.casesOrdered){
                data.push({
                    name: "Cases",
                    ordered: variation.casesOrdered,
                    completed: variation.casesCompleted,
                    refunded: variation.casesRefunded,
                    outstanding: variation.casesOrdered - variation.casesCompleted - variation.casesRefunded
                })
            }
            return data
        },
        getCountForOptionChoiceAndVariation(option, choice, variation){
            let count = 0
            if(option.count[choice] && variation.options[option.name][choice]){
                count = variation.options[option.name][choice]
            }
            return count
        },
        formatDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").format("M/D/YYYY ha")
        },
        shortDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").calendar()
        },
        lastDay (dateStr) {
            let diff = moment(dateStr, "YYYY-MM-DD @HH:mm").diff(moment(), "hours")
            return diff < 24
        },
        productUpdated(event) {
            this.$emit('product-updated', event)
        },
    }
}
</script>

<style scoped>
.tablebox {
  max-width: 800px;
  margin: 0 auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: auto;
    border-spacing: 1px;
}

td, th {
  border: 1px solid #dddddd;
  text-align: right;
  padding: 5px;
  height: 10px
}

th:nth-child(1) {
  text-align: left;
}
.narrow-table {
  border-collapse: collapse;
  display: inline-table;
}

.narrow-table th,
.narrow-table td {
  border: 1px solid #ccc;
  text-align: center;
  padding: 1px 4px;
  white-space: nowrap;
  min-width: 0;
  font-size: 14px; /* Adjust the font size as needed */
  width: 25%; /* Adjust the percentage based on the number of columns */
}


.narrow-table thead {
  font-weight: bold;
}

.footer-cell {
  text-align: center;
}

.narrow-table tfoot {
  font-weight: bold;
}

</style>